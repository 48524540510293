// Levels.js
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { MyContext } from "../../App";
import "./Level.css";

const Levels = () => {
  const navigate = useNavigate();
  const { selectedLevel, setSelectedLevel } = useContext(MyContext);

  // Handle level selection
  const handleLevelSelect = (level) => {
    setSelectedLevel(level);
    console.log(level);
  };

  // Handle navigation to the next page
  const handleNextPage = () => {
    if (selectedLevel) {
      navigate("/technologies");
      console.log(selectedLevel);
    } else {
      alert("Please select a level before proceeding.");
    }
  };

  return (
    <div className="levels-page">
      <h2>Select Your Level</h2>
      <div className="level-buttons">
        <button
          onClick={() => handleLevelSelect("Basic")}
          className={selectedLevel === "Basic" ? "selected" : ""}
        >
          Basic
        </button>
        <button
          onClick={() => handleLevelSelect("Intermediate")}
          className={selectedLevel === "Intermediate" ? "selected" : ""}
        >
          Intermediate
        </button>
        <button
          onClick={() => handleLevelSelect("Expert")}
          className={selectedLevel === "Expert" ? "selected" : ""}
        >
          Expert
        </button>
      </div>
      <button onClick={handleNextPage}>Next Page</button>
    </div>
  );
};

export default Levels;
