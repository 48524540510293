import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MyContext } from "../App";
import "./Domainpage.css"; // Make sure the path is correct
import axios from "axios";

const Nonitpages = () => {
  const navigate = useNavigate();
  const {
    setSelectedOption,
    setLevel,
    setSelectedMatches,
    setUserData,
    setSessionData,
  } = useContext(MyContext);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };

  const fetchAllUsersData = async () => {
    try {
      const response = await axios.get(
        "https://www.interviewaxis.com/api/v1/user-data",
        { withCredentials: true }
      );

      if (response.status >= 200 && response.status < 300) {
        return Array.isArray(response.data.data) ? response.data.data : [];
      } else {
        throw new Error("Failed to fetch users data");
      }
    } catch (error) {
      console.error("Error fetching users data:", error);
      return [];
    }
  };

  const checkSessionStatus = async () => {
    try {
      const response = await axios.get(
        "https://www.interviewaxis.com/api/v1/check-session",
        { withCredentials: true }
      );

      const interviewData = response.data.interviewData;

      setSessionData(interviewData);
      setSelectedOption(interviewData.industry === "2" ? "NON IT" : "IT");
      setSelectedMatches(interviewData.upload_file);
      let level;
      const levelSelected = parseInt(interviewData.level_selected, 10);

      if (levelSelected >= 0 && levelSelected <= 3) {
        level = "Basic";
      } else if (levelSelected > 3 && levelSelected <= 7) {
        level = "Intermediate";
      } else if (levelSelected > 7 && levelSelected <= 30) {
        level = "Expert";
      } else {
        level = "Unknown"; // Handle unexpected values
      }

      setLevel(level);

      return response.data;
    } catch (error) {
      console.error("Error checking session status:", error);
      return null;
    }
  };

  const processSessionData = async () => {
    const usersData = await fetchAllUsersData();
    const sessionData = await checkSessionStatus();

    if (sessionData && sessionData.logged_in) {
      const loggedInUser = sessionData.user;
      const matchedUser = usersData.find((user) => user.id === loggedInUser.id);

      if (matchedUser) {
        setUserData({
          user_id: matchedUser.id,
          user_name: matchedUser.name,
          user_role: loggedInUser.role,
        });
        navigate("/custominterview");
      } else {
        console.error("User data not found in users data");
      }
    } else {
      window.location.href = "https://www.interviewaxis.com/login";
    }
  };

  useEffect(() => {
    processSessionData();
  }, []);

  return (
    <div className="it-or-non-it-page">
      {/* Content or loading spinner can be added here */}
    </div>
  );
};

export default Nonitpages;
