import React, { createContext, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Levels from "../src/DeafultInterview/Levels/Levels";
import Technologies from "../src/DeafultInterview/Technology/Technologies";
import Condations from "./DeafultInterview/CondiationsPage/Condations";
import Thankyou from "./DeafultInterview/ThankyouWaitPage/Thankyou";
import TimerScreen from "./DeafultInterview/TimerScreen/TimerScreen";
import InterviewSereen from "./DeafultInterview/Interviewscreen/InterviewSereen";
import Domainpage from "./Domainpage/Domainpage";
import Nonitpages from "./Domainpage/Nonitpages";
import CustmInterviewScreen from "./Custominterview/Custominterviewscreen/CustmInterviewScreen";
import CustomTimerScreen from "./Custominterview/Customtimerscreen/CustomTimerScreen";

export const MyContext = createContext();

const App = () => {
  const [selectedOption, setSelectedOption] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState("");
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [userData, setUserData] = useState(null);
  const [sessionData, setSessionData] = useState({});
  // custom interview
  const [level, setLevel] = useState("");
  const [questionsAndAnswers, setQuestionsAndAnswers] = useState([]);
  const [selectedMatches, setSelectedMatches] = useState([]);

  return (
    <MyContext.Provider
      value={{
        selectedOption,
        setSelectedOption,
        selectedLevel,
        setSelectedLevel,
        selectedLanguages,
        setSelectedLanguages,
        setUserData,
        userData,
        setSessionData,
        sessionData,
        //custom interview
        level,
        setLevel,
        selectedMatches,
        setSelectedMatches,
        questionsAndAnswers,
        setQuestionsAndAnswers,
      }}
    >
      <Router>
        <Routes>
          <Route path="/" element={<Domainpage />} />
          {/* <Route path="/levels" element={<Levels />} />
          <Route path="/Technologies" element={<Technologies />} />
          <Route path="/Condations" element={<Condations />} />
          <Route path="/TimerScreen" element={<TimerScreen />} /> */}
          <Route path="/InterviewSereen" element={<InterviewSereen />} />
          <Route path="/Thankyou" element={<Thankyou />} />
          <Route path="/nonitpages" element={<Nonitpages />} />
          {/* custominterview */}
          <Route path="/nonit" element={<Nonitpages />} />
          <Route path="/customtimer" element={<CustomTimerScreen />} />
          <Route path="/custominterview" element={<CustmInterviewScreen />} />
        </Routes>
      </Router>
    </MyContext.Provider>
  );
};

export default App;
