// import React from "react";
// import "./Thankyou.css";

// const Thankyou = () => {
//   return (
//     <>
//       <div>Thankyou for the interview </div>
//     </>
//   );
// };

// export default Thankyou;

import React, { useState, useEffect } from "react";
import axios from "axios";

const Custemcmpscreen = () => {
  const [individualScore, setIndividualScore] = useState(0);
  const [totalScore, setTotalScore] = useState({});

  useEffect(() => {
    const fetchScores = async () => {
      try {
        const response = await axios.post(
          "http://127.0.0.1:5000/custom_report",
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const { total_similarity_score, category_scores } = response.data;
        setIndividualScore(total_similarity_score);
        setTotalScore(category_scores);
      } catch (error) {
        console.error("Error fetching scores:", error);
      }
    };

    fetchScores();
  }, []);

  return (
    <div>
      <h1>Interview Completion Screen</h1>
      <p>total Score: {individualScore}</p>
      <div>
        <h2>Category Scores:</h2>
        {Object.keys(totalScore).length === 0 ? (
          <p>No category scores available</p>
        ) : (
          <ul>
            {Object.entries(totalScore).map(([category, score]) => (
              <li key={category}>
                {category}: {score}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default Custemcmpscreen;
