import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from "react";
import Arrow from "../../assets/Interviewimages/arrow.svg";
import "./style.css";
import { Link } from "react-router-dom";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
//import Cansvsvideo from "../Main/Canvesvideo";
import "line-awesome/dist/line-awesome/css/line-awesome.min.css";
import { MyContext } from "../../App";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import dat from "dat.gui";
import { Badge } from "@mui/material";
import { Check, Close } from "@mui/icons-material";
import './mystyle.css';
import tick from '../../assets/Images/tick.png';
import wrong from '../../assets/Images/wrong.png';
import success from '../../assets/Images/success.gif';
import Swal from 'sweetalert2';

const WIDTH = 500; // Define the width of the canvas
const HEIGHT = 350;

const convertData = (data) => {
  let individual_scores = {
    QA: {},
  };
  let questions_skipped = 0;
  let time_taken_per_question = {};
  let total_time_taken = 0;
  let aggregate_score = 0;
  data.forEach((item, index) => {
    let questionNumber = `Question ${index + 1}`;
    let score = item.similarityScore;
    individual_scores["QA"][questionNumber] = item.similarityScore || 0;
    aggregate_score += score || 0;

    if (!item.answered) {
      questions_skipped += 1;
    }

    time_taken_per_question[questionNumber] = item.timeTaken || 0;
    total_time_taken += item.timeTaken || 0;
  });

  return {
    individual_scores,
    questions_skipped,
    time_taken_per_question,
    aggregate_score,
  };
};

const InterviewSereen = () => {
  const {
    selectedOption,
    selectedLevel1,
    selectedLanguages1,
    userData,
    sessionData,
  } = useContext(MyContext);


  

  const mydomain = sessionData.industry; 
  const interviewtype = sessionData.interview_type;
  const industry_type = sessionData.industry;
  const tot_experience = sessionData.experience;
  const useridd = userData.user_id;
  const user_name = userData.user_name;
  const role = userData.user_role;
  //const techValues = sessionData.technologies_selected;
  const user_email = sessionData.email;
  const selectedLevel = "2";
  const techValues = (() => { //copy
    try {
      const parsedValues = JSON.parse(sessionData.technologies_selected);
      return Array.isArray(parsedValues)
        ? parsedValues.map((value) => parseInt(value, 10)).filter((num) => !isNaN(num))
        : [];
    } catch (error) {
      console.error("Error parsing techValues:", error);
      return [];
    }
  })();
  
  const [realdomain, setrealdomain] = useState(null);
  const selectedLanguages = "eng";
  const [countdown, setCountdown] = useState(120);
  const [totalTime, setTotalTime] = useState(0);
  const [isSecondTimerActive, setIsSecondTimerActive] = useState(false);
  const [isWebcamActive, setIsWebcamActive] = useState(false);
  const videoRef = useRef(null);
  const [isMicrophoneOn, setIsMicrophoneOn] = useState(false);
  const { transcript, resetTranscript, browserSupportsSpeechRecognition } =  useSpeechRecognition();
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [questionsAndAnswers, setQuestionsAndAnswers] = useState([]);
  const [correctAnswer, setCorrectAnswer] = useState("");
  const [skippedCount, setSkippedCount] = useState(0);
  const audioRef = useRef(null);
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);
  const scrollRef = useRef(null);
  const [isTextCleared, setIsTextCleared] = useState(false);
  const textAreaRef = useRef(null);
  const navigate = useNavigate();
  const inactivityTimeoutRef = useRef(null);
  const [seconds, setSeconds] = useState(5);
  const [countdownActive, setCountdownActive] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const isFirstRun = useRef(true);
  const timerIdRef = useRef(null);
  const isNextQuestionCalled = useRef(false);
  const [isRequestSent, setIsRequestSent] = useState(false); 
  const [fetched_tech, setfetched_tech] = useState(null);  
  const [showMic, setShowMic] = useState(false);

  const [isApiCalled, setIsApiCalled] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [opts, setOpts] = useState({
    smoothing: 0.6,
    fft: 8,
    minDecibels: -70,
    scale: 0.2,
    glow: 10,
    color1: [203, 36, 128],
    color2: [41, 200, 192],
    color3: [24, 137, 2120],
    fillOpacity: 0.6,
    lineWidth: 1,
    blend: "screen",
    shift: 50,
    width: 60,
    amp: 1,
  });

  const canvasRef = useRef(null);
  const contextRef = useRef(null);
  const analyserRef = useRef(null);
  const freqsRef = useRef(new Uint8Array(256));
  
  useEffect(() => {
    if (question) {
      setCountdown(120); 
    }
  }, [question]);
  

  const currentTechIndex = useRef(0);
  const uniqueQuestions = useRef(new Set()); 

  useEffect(() => {
    const fetchInitialQuestion = async () => {
      if (selectedLanguages && selectedLevel) {    
        try {
          const currentTechValue = techValues[currentTechIndex.current];
          currentTechIndex.current = (currentTechIndex.current + 1) % techValues.length;

          const response = await fetch("https://www.compareduck.com/question", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              sub_category: [currentTechValue],
             // sub_category: JSON.parse(techValues).map(value => parseInt(value)),
              level_type: selectedLevel === '1'
              ? "Basic"
              : selectedLevel === '2'
              ? "Intermediate"
              : selectedLevel === '3'
              ? "Expert"
              : "Unknown",
            }),
          });
          const data = await response.json();
          console.log(data)
          const { question, correct_answer, audio } = data;
  
          setCorrectAnswer(correct_answer);
          setQuestionsAndAnswers([
            {
              realquestion: question,
              question: question,
              correctAnswer: correct_answer,
              audio: audio,
              revealed: true,
              userAnswer: "",
              similarityScore: null,
              answered: false,
              skipped: false,
            },
          ]);
          setIsSecondTimerActive(true);
          setActiveQuestionIndex(0);
  
          speakQuestion(question)
            .then(() => {
              setQuestion(false);
              setCountdownActive(true); 
              setShowMic(false);
            })
            .catch((error) => {
              console.error("Error speaking question:", error);
            });
        } catch (error) {
          console.error("Error fetching initial question:", error);
        }
      }
    };
  
    if (isFirstRun.current) {
      fetchInitialQuestion();
      isFirstRun.current = false;
    }
  }, [selectedLanguages, selectedLevel]); 
  
  
  useEffect(() => {
    if (countdownActive && seconds > 0) {
   
      const countdownInterval = setInterval(() => {
        setSeconds((prev) => prev - 1);
      }, 1000);
  
      return () => clearInterval(countdownInterval);
    } else if (countdownActive && seconds === 0) {
    
      setCountdownActive(false); 
      setIsMicrophoneOn(true);  
  
      if (SpeechRecognition.browserSupportsSpeechRecognition()) {
        SpeechRecognition.stopListening(); 
        resetTranscript();
        SpeechRecognition.startListening({
          continuous: true,
          language: "en-IN", 
        })
          .then(() => {
            console.log("Microphone started listening");
          })
          .catch((error) => {
            console.error("Error starting microphone:", error);
          });
      } else {
        console.error("Speech recognition is not supported in this browser.");
      }
    }
  }, [countdownActive, seconds]);
  
  
  useEffect(() => {
    if (countdownActive) {
      const blinkInterval = setInterval(() => {
        setIsVisible((prev) => !prev);
      }, 500);
  
      return () => clearInterval(blinkInterval);
    }
  }, [countdownActive]);
  

  const maxQuestionsToRender = 15 + skippedCount;

  const getNextQuestion = useCallback(async () => {
    if (questionsAndAnswers.length < maxQuestionsToRender) {
      if (selectedLanguages && selectedLevel) {


        try {
           const currentTechValue = techValues[currentTechIndex.current];
          currentTechIndex.current = (currentTechIndex.current + 1) % techValues.length;
          const response = await fetch("https://www.compareduck.com/question", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              sub_category: [currentTechValue], 
             // sub_category: JSON.parse(techValues).map(value => parseInt(value)),
              level_type: selectedLevel === '1'
              ? "Basic"
              : selectedLevel === '2'
              ? "Intermediate"
              : selectedLevel === '3'
              ? "Expert"
              : "Unknown",
            }),
          });
  
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
  
          const data = await response.json();
          console.log(data)
          const { question, correct_answer, audio } = data;
  
          setQuestionsAndAnswers((prev) => {
            const newQaList = [
              ...prev,
              {
                realquestion: question,
                question: question,
                correctAnswer: correct_answer,
                audio: audio,
                revealed: false,
                userAnswer: "",
                similarityScore: null,
                skipped: false,
                answered: false,
              },
            ];
            return newQaList.slice(-maxQuestionsToRender);
          });
        
          setIsMicrophoneOn(false);
          setQuestion(question);
          setCorrectAnswer(correct_answer);  
          
          SpeechRecognition.stopListening();
          resetTranscript(); 

          speakQuestion(question)         
            .then(() => {
              setActiveQuestionIndex((prev) => prev + 1);
              setIsSecondTimerActive(true);
              setSeconds(5);
              setCountdownActive(true); 
              setShowMic(false);
            })
            .catch((error) => {
              console.error("Error speaking question:", error);
            });
  
        } catch (error) {
          console.error("Error fetching question:", error);
        }
      } else {
        // alert("Please select a language and level type.");
      }
    } else {
      alert("Your questions are completed.");
    }
  }, [selectedLanguages, selectedLevel, questionsAndAnswers.length, maxQuestionsToRender]);
  
  useEffect(() => {
    if (countdownActive) {
      setSeconds(5); 
    }
  }, [countdownActive]);
 

  useEffect(() => {
    const getdomain = async () => {
      try {
        const response = await fetch('https://www.interviewaxis.com/api/v1/category', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          }
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = await response.json();
        const filteredItem = result.data.find(item => item.id === parseInt(mydomain));


        if (filteredItem) {
          setrealdomain(filteredItem.name);
        } else {
          setrealdomain('ID not found');
        }
        
      } catch (error) {
        console.error('There was an error!', error);
      }
    };
    getdomain();
  }, []);


  useEffect(() => {  //copy this function
    const fetchtech = async () => {
      try {
        const response = await fetch('https://www.interviewaxis.com/api/v1/childcategory', { 
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        const result = await response.json();
        console.log("API Response Data:", result.data);
  
        const filteredTechNames = result.data
          .filter((item) => techValues.includes(parseInt(item.id)))
          .map((item) => item.name);
  
        console.log("Filtered Tech Names:", filteredTechNames);
  
        setfetched_tech(filteredTechNames);
      } catch (error) {
        console.error("There was an error fetching tech data:", error);
      }
    };
  
    fetchtech();
  }, []);

 
   
const checkAnswer = async () => {
  const formattedTimetaken = formatTime(120 - countdown);
  const formattedTotalTime = formatTotalTime(120 - totalTime + 5);

  try {
    const response = await axios.post("https://compareduck.com/answer", {
      user_answer: answer,
      correct_answer: correctAnswer,
    });

    const { similarity_score } = response.data;

    setQuestionsAndAnswers((prev) => {
      const newQaList = [...prev];
      newQaList[activeQuestionIndex] = {
        ...newQaList[activeQuestionIndex],
        userAnswer: answer,
        similarityScore: similarity_score,
        answered: true,
        timeTaken: formattedTimetaken,
        totaltime: formattedTotalTime,
        realquestion: newQaList[activeQuestionIndex].realquestion,
        question: "Question",
        ...userData,
      };
      console.log("sk",activeQuestionIndex);
      if (activeQuestionIndex + 1 === maxQuestionsToRender && !hasSubmitted) {
        postInterviewData(newQaList);
      }
     
      return newQaList;
    });

    SpeechRecognition.stopListening();
    resetTranscript();
    setAnswer("");
    setShowMic(true);
    setIsMicrophoneOn(false);
    
  } catch (error) {
    console.error("Error sending request:", error);
  }
};

const postInterviewData = async (updatedQaList) => {
  if (hasSubmitted) return; // Prevent duplicate submissions

  setHasSubmitted(true); 
  let body = convertData(updatedQaList); // Use the passed updated state

  try {
    const _body = {
      ...body,
      ...userData,
      ...sessionData,
      technologies_selected: techValues,
      total_time_taken: totalTime,
      level_selected: selectedLevel,
      interview_type: interviewtype,
      industry: industry_type,
      user_id: useridd,
      user_name: user_name,
      user_role: role,
      user_email: user_email,
      allquestions: updatedQaList,  // Use the passed updated state here
    };

    const response = await axios.post(
      "https://www.interviewaxis.com/api/v1/interviews",
      _body
    );

    const forgotSessionResponse = await fetch('https://interviewaxis.com/api/v1/forgotsession', {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    });

     
    Swal.fire({
      title: 'Thank you for attending the interview',
      html: `
        <p>Please wait while we generate your detailed report</p>
        <img src="${success}" style="width:70%" alt="Loading..." />
      `,
      showConfirmButton: false,
      allowOutsideClick: false,
      timer: 4000,
      timerProgressBar: true,
    }).then(() => {     
      window.location.href = 'https://www.interviewaxis.com/interview-reports';
    });
  } catch (error) {
    console.error("Error posting interview data:", error);
  }
};

  const speakQuestion = (questionText) => {
    return new Promise((resolve, reject) => {
      const synth = window.speechSynthesis;
      if (synth.speaking) {
        synth.cancel(); // Stop any ongoing speech
      }
      setShowMic(true);
      const utterThis = new SpeechSynthesisUtterance(questionText);
  
      utterThis.onend = () => {
        console.log("SpeechSynthesisUtterance.onend");
        resolve(); // Resolve the promise when speaking is finished
      };
  
      utterThis.onerror = (event) => {
        console.error("SpeechSynthesisUtterance.onerror", event);
        reject(event); // Reject the promise if there's an error
      };
  
      synth.speak(utterThis);
    });
  };
  
  
  // // Speak question
  // const speakQuestion = (questionText) => {
  //   const synth = window.speechSynthesis;
  //   if (synth.speaking) {
  //     synth.cancel();
  //   }

  //   const utterThis = new SpeechSynthesisUtterance(questionText);
  //   utterThis.onend = () => {
  //     console.log("SpeechSynthesisUtterance.onend");
  //   };

  //   utterThis.onerror = (event) => {
  //     console.error("SpeechSynthesisUtterance.onerror", event);
  //   };

  //   synth.speak(utterThis);
  // };

  const handleSkipQuestion = (index) => {
    const currentQuestion = questionsAndAnswers[index];

    if (
      currentQuestion.userAnswer &&
      currentQuestion.userAnswer.trim() !== ""
    ) {
      alert(
        "You have already given an answer to this question. Please select a new question."
      );
      return;
    }

    if (!currentQuestion.userAnswer && skippedCount < 5) {
      setQuestionsAndAnswers((prev) => {
        const updatedQaList = [...prev];
        updatedQaList[index].skipped = "skipped";
        return updatedQaList;
      });

      setSkippedCount((prevCount) => prevCount + 1); // Increment skipped count
      SpeechRecognition.stopListening();
      resetTranscript();  
      getNextQuestion(); // Fetch the next question after marking the current one as skipped
    } else if (skippedCount >= 5) {
      alert("You have already skipped 5 questions.");
    } else {
      alert("Submit your answer before skipping the question.");
    }
  };

  // Microphone
  const toggleMicrophone = () => {
    if (!isMicrophoneOn) {
      if (isTextCleared) {
        resetTranscript(); // Reset transcript only if text is manually cleared
        setIsTextCleared(false); // Reset the flag
      }

      SpeechRecognition.startListening({ continuous: true, language: "en-In" });
      setTimeout(() => {
        SpeechRecognition.stopListening();
        setIsMicrophoneOn(false);
      }, 300000); // Stop listening after 5 minutes
    } else {
      SpeechRecognition.stopListening();
    }
    setIsMicrophoneOn(!isMicrophoneOn);
  };

  // useEffect(() => {
  //   if (!browserSupportsSpeechRecognition) {
  //     alert(
  //       "Your browser doesn't support speech recognition. Please use a different browser."
  //     );
  //   }
  // }, [browserSupportsSpeechRecognition]);

  useEffect(() => {
    if (transcript && isMicrophoneOn && !isTextCleared) {
      setAnswer(transcript);
    } else if (!transcript && isTextCleared && isMicrophoneOn) {
      setAnswer(true); // Clear the answer when transcript is empty and text is manually cleared and microphone is off
    }

    // Scroll to the end of textarea when answer changes
    if (textAreaRef.current) {
      textAreaRef.current.scrollTop = textAreaRef.current.scrollHeight;
    }
  }, [transcript, isMicrophoneOn, isTextCleared, answer]);


  useEffect(() => {
    if (question) {
      setCountdown(120); 
      isNextQuestionCalled.current = false; 
    }
  }, [question]);

  useEffect(() => {
    if (isMicrophoneOn) {     
      if (!timerIdRef.current) {
        timerIdRef.current = setInterval(() => {
          setCountdown((prevCountdown) => {
            if (prevCountdown <= 1) {
              clearInterval(timerIdRef.current);
              timerIdRef.current = null; 
              if (!isNextQuestionCalled.current) {
                isNextQuestionCalled.current = true;
                getNextQuestion();
              }
              return 0;
            }
            return prevCountdown - 1;
          });
        }, 1000);
      }
    }

    return () => {
      if (timerIdRef.current) {
        clearInterval(timerIdRef.current);
        timerIdRef.current = null; 
      }
    };
  }, [isMicrophoneOn, getNextQuestion]);

  // useEffect(() => {
  //   let timerId;
  //   if (isMicrophoneOn) {
  //     timerId = setInterval(() => {
  //       setCountdown((prevCountdown) => {
  //         if (prevCountdown <= 1) {
  //           clearInterval(timerId);
  //           // Handle the transition to the next question or completion
  //           getNextQuestion();
  //           return 0;
  //         }
  //         return prevCountdown - 1;
  //       });
  //     }, 1000);
  //   }
  //   return () => clearInterval(timerId);
  // }, [isMicrophoneOn, getNextQuestion]);

  // Timer for totalTime
  useEffect(() => {
    let interval2;
    if (isSecondTimerActive) {
      interval2 = setInterval(() => {
        setTotalTime((prev) => (prev < 30 * 60 ? prev + 1 : 30 * 60)); // 30 minutes timer
      }, 1000);
    }

    return () => {
      clearInterval(interval2);
    };
  }, [isSecondTimerActive]);

  // Toggle webcam on/off
  const toggleWebcam = () => {
    setIsWebcamActive(!isWebcamActive);
  };

  useEffect(() => {
    const userCamera = () => {
      if (isWebcamActive) {
        navigator.mediaDevices
          .getUserMedia({ video: true })
          .then((stream) => {
            let video = videoRef.current;
            video.srcObject = stream;
          })
          .catch((error) => {
            console.error("Error accessing webcam: ", error);
          });
      } else {
        let video = videoRef.current;
        if (video.srcObject) {
          let tracks = video.srcObject.getTracks();
          tracks.forEach((track) => track.stop());
        }
        video.srcObject = null;
      }
    };

    userCamera();
  }, [isWebcamActive]);

  // Format time functions
  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(mins).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
  };

  const formatTotalTime = (seconds) => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${String(hrs).padStart(2, "0")}:${String(mins).padStart(
      2,
      "0"
    )}:${String(secs).padStart(2, "0")}`;
  };

  // Scroll up function
  const scrollToTop = () => {
    scrollRef.current.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const scrollToBottom = () => {
    scrollRef.current.scrollTo({
      top: scrollRef.current.scrollHeight,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    // Scroll to top when the active question changes
    scrollToBottom();
  }, [activeQuestionIndex]);

  const handleUserActivity = () => {
    clearTimeout(inactivityTimeoutRef.current);  
    inactivityTimeoutRef.current = setTimeout(async () => {
      Swal.fire({
        title: 'Inactivity Detected!',
        text: 'Since there was no response for 5 minutes, the interview is being terminated.',
        icon: 'warning',
        confirmButtonText: 'OK'
      }).then(async () => {
        await fetch('https://interviewaxis.com/api/v1/forgotsession', {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          }
        });  
        window.location.href = 'https://www.interviewaxis.com/';
      });
    }, 300000); // 5 minutes in milliseconds (300,000)
  };
  

  useEffect(() => {
    // Add event listeners for user activity
    document.addEventListener("mousemove", handleUserActivity);
    document.addEventListener("keypress", handleUserActivity);

    // Set the initial inactivity timeout
    handleUserActivity();

    return () => {
      // Cleanup event listeners on unmount
      document.removeEventListener("mousemove", handleUserActivity);
      document.removeEventListener("keypress", handleUserActivity);
    };
  });

 
  //   const handleResize = async () => {
  //     Swal.fire({
  //       title: 'Resize Detected!',
  //       text: 'Interview will be terminated if two windows are open side by side.',
  //       icon: 'warning',
  //       confirmButtonText: 'OK'
  //     }).then(async () => {
  //       await fetch('https://interviewaxis.com/api/v1/forgotsession', {
  //         method: 'GET',
  //         credentials: 'include',
  //         headers: {
  //           'Content-Type': 'application/json',
  //         }
  //       });
  //       window.location.href = 'https://www.interviewaxis.com/';
  //     });
  //   };
  
  //   window.addEventListener('resize', handleResize);
  
  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, [navigate]);

  // // user open other windows refresh the page
  // useEffect(() => {
  //   const handleVisibilityChange = async () => {
  //     if (document.hidden) {
  //       Swal.fire({
  //         title: 'Tab Switch Detected!',
  //         text: 'Interview terminated due to changing of tabs.',
  //         icon: 'warning',
  //         confirmButtonText: 'OK'
  //       }).then(async () => {
  //         await fetch('https://interviewaxis.com/api/v1/forgotsession', {
  //           method: 'GET',
  //           credentials: 'include',
  //           headers: {
  //             'Content-Type': 'application/json',
  //           }
  //         });  
  //         window.location.href = 'https://www.interviewaxis.com/';
  //       });
  //     }
  //   };

  //   document.addEventListener('visibilitychange', handleVisibilityChange);  
  //   return () => {
  //     document.removeEventListener('visibilitychange', handleVisibilityChange);
  //   };
  // }, []);
  

  /// canves video animation

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    navigator.getUserMedia =
      navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.msGetUserMedia;

    const AudioContext = window.AudioContext || window.webkitAudioContext;
    const context = new AudioContext();
    const analyser = context.createAnalyser();

    contextRef.current = context;
    analyserRef.current = analyser;

    let animationId;
    const path = (ctx, channel) => {
      const h = HEIGHT;
      const color = opts[`color${channel + 1}`].map(Math.floor);
      ctx.fillStyle = `rgba(${color}, ${opts.fillOpacity})`;
      ctx.strokeStyle = ctx.shadowColor = `rgb(${color})`;
      ctx.lineWidth = opts.lineWidth;
      ctx.shadowBlur = opts.glow;
      ctx.globalCompositeOperation = opts.blend;

      const m = HEIGHT / 2;
      const offset = (WIDTH - 15 * opts.width) / 2;
      const x = range(15).map(
        (i) => offset + channel * opts.shift + i * opts.width
      );
      const y = range(5).map((i) =>
        Math.max(0, m - scale(i) * freq(channel, i))
      );

      ctx.beginPath();
      ctx.moveTo(0, m);
      ctx.lineTo(x[0], m + 1);
      ctx.bezierCurveTo(x[1], m + 1, x[2], y[0], x[3], y[0]);
      ctx.bezierCurveTo(x[4], y[0], x[4], y[1], x[5], y[1]);
      ctx.bezierCurveTo(x[6], y[1], x[6], y[2], x[7], y[2]);
      ctx.bezierCurveTo(x[8], y[2], x[8], y[3], x[9], y[3]);
      ctx.bezierCurveTo(x[10], y[3], x[10], y[4], x[11], y[4]);
      ctx.bezierCurveTo(x[12], y[4], x[12], m, x[13], m);
      ctx.lineTo(1000, m + 1);
      ctx.lineTo(x[13], m - 1);

      ctx.bezierCurveTo(x[12], m, x[12], h - y[4], x[11], h - y[4]);
      ctx.bezierCurveTo(x[10], h - y[4], x[10], h - y[3], x[9], h - y[3]);
      ctx.bezierCurveTo(x[8], h - y[3], x[8], h - y[2], x[7], h - y[2]);
      ctx.bezierCurveTo(x[6], h - y[2], x[6], h - y[1], x[5], h - y[1]);
      ctx.bezierCurveTo(x[4], h - y[1], x[4], h - y[0], x[3], h - y[0]);
      ctx.bezierCurveTo(x[2], h - y[0], x[1], m, x[0], m);
      ctx.lineTo(0, m);
      ctx.fill();
      ctx.stroke();
    };

    const range = (i) => {
      return Array.from(Array(i).keys());
    };

    const freq = (channel, i) => {
      const band = 2 * channel + shuffle[i] * 6;
      return freqsRef.current[band];
    };

    const scale = (i) => {
      const x = Math.abs(2 - i);
      const s = 3 - x;
      return (s / 3) * opts.amp;
    };

    const shuffle = [1, 3, 0, 4, 2];

    const visualize = () => {
      analyser.smoothingTimeConstant = opts.smoothing;
      analyser.fftSize = Math.pow(2, opts.fft);
      analyser.minDecibels = opts.minDecibels;
      analyser.maxDecibels = 0;
      analyser.getByteFrequencyData(freqsRef.current);

      canvas.width = WIDTH;
      canvas.height = HEIGHT;

      path(ctx, 0);
      path(ctx, 1);
      path(ctx, 2);

      if (isMicrophoneOn) {
        animationId = requestAnimationFrame(visualize);
      }
    };

    const onStream = (stream) => {
      const input = context.createMediaStreamSource(stream);
      input.connect(analyser);
      visualize();
    };

    const onStreamError = (e) => {
      document.body.innerHTML = "<h1>This pen only works with https://</h1>";
      console.error(e);
    };

    const gui = new dat.GUI();
    Object.keys(opts).forEach((key) => {
      if (Array.isArray(opts[key])) {
        gui
          .addColor(opts, key)
          .onChange((value) => setOpts((prev) => ({ ...prev, [key]: value })));
      } else if (typeof opts[key] === "number") {
        gui
          .add(opts, key)
          .onChange((value) => setOpts((prev) => ({ ...prev, [key]: value })));
      } else {
        gui
          .add(opts, key, [
            "normal",
            "multiply",
            "screen",
            "overlay",
            "lighten",
            "difference",
          ])
          .onChange((value) => setOpts((prev) => ({ ...prev, [key]: value })));
      }
    });

    navigator.getUserMedia({ audio: true }, onStream, onStreamError);

    return () => {
      gui.destroy();
      context.close();
      cancelAnimationFrame(animationId);
    };
  }, [opts, isMicrophoneOn]);

  function startMicCounter() {
    var count = 5;
    var countdownInterval = setInterval(() => {
      count <= 1 && clearInterval(countdownInterval);

      //console.log(--count);
      // number.textContent = count <= 10 ? `0${--count}` : `${--count}`;
    }, 1000);
  }

  startMicCounter();

  return (
    <>
      {/* {totalTime <= 5 && (
         <div>
         <h2>Your Mic is starting in 5 sec's</h2>
        </div>
      )} */}

      <div className="container-fluid main-section">
        <div className="row align-items-center padding-class mobpadding">
          {/* Left Column */}
          <div className="col-lg-4 box-padding desktp" style={{ width: '29%' }}>
            {/* Selected Fields */}
            <div className="dark-box">
              <h3 className="text-white">
                <b>Selected Fields</b>
              </h3>
              <ul className="list-unstyled fields">
                <li>
                  <span style={{ paddingLeft: '10px' }}>1. Domain: {realdomain}</span>
                 
                </li>
                <li>
                <span style={{ paddingLeft: '10px' }}>
  2. Technology:
  {fetched_tech && fetched_tech.length > 0 ? (
      <span style={{ paddingLeft: '3px' }}>{fetched_tech.join(", ")}</span> 
  ) : fetched_tech === null ? (
    <span>Loading...</span>
  ) : (
    <span>No names</span>
  )}
</span>

                 
                   
                </li>
                <li>
                <span style={{ paddingLeft: "10px" }}>
                    3. Years of Experience: 
                    {tot_experience && tot_experience.length > 0 ? (
                      <span style={{ paddingLeft: "3px" }}>
                        {tot_experience}
                      </span>
                    ) : tot_experience === null ? (
                      <span>Loading...</span>
                    ) : (
                      <span>No exp</span>
                    )}
                  </span>
                 
                </li>
              </ul>
            </div>
            {/* Webcam Video */}
            <div className="mt-4 video-wrap">
              {!isWebcamActive && <i className="la la-user candidate-icon"></i>}
              <video ref={videoRef} id="video" autoPlay></video>
            </div>
          </div>

          {/* Center Column */}
          <div id="dis-pos" className="col-lg-5 disp-flex   position-relative ">
            {/* Canvas */}
            <div
              className="canves-class mobcanva"
              style={{
                marginTop: 100,
              }}
            >
              <canvas
                id="canvas"
                ref={canvasRef}
                width={WIDTH}
                height={HEIGHT}
              />
               {seconds > 0 && countdownActive ? (
        <h1
          style={{
            position: "absolute",
            left: "50%",
            transform: "translate(-50%, 0)",
            display: "flex",
            color: "white",
            fontSize: "22px",
            marginTop: "100px",
            visibility: isVisible ? "visible" : "hidden", // Use visibility for blinking effect
          }}
        >
          Your Mic is starting
        </h1>
      ) : (
        <h1></h1>
      )}
            </div>

            
			<p style={{color:'#fff',position: 'relative',top: '-100px'}}>{correctAnswer}</p>
            {/* Bottom Buttons */}
            <div className="text-center bottom-btns mobpos">
              <ul className="list-unstyled call-navs">
                {/* Toggle Webcam Button */}
                <li>
                  <Link to="#" className="video" onClick={toggleWebcam}>
                    <i
                      className={`la ${
                        isWebcamActive ? "la-video" : "la-video-slash"
                      }`}
                    ></i>
                  </Link>
                </li>
                {/* Toggle Microphone Button */}
                <li>
                {seconds > 0 || showMic ? (
    <Badge
    badgeContent={showMic ? 5 : seconds} 
      color="error"
      classes={{ badge: seconds > 0 && countdownActive ? (isVisible ? "blinking-badge" : "") : "" }}
      style={{ marginTop: '-10px' }}
    >
      <Link to="#" className="mic">
        <i className={`la la-microphone-slash`}></i>
      </Link>
    </Badge>
  ) : (
    <Link to="#" className="mic">
      <i className="la la-microphone"></i>
    </Link>
    // <Link to="#" className="mic" onClick={toggleMicrophone}></Link>
  )}
</li>


              </ul>
              {/* Skip Question Button */}
              <button
                onClick={() => handleSkipQuestion(activeQuestionIndex)}
                className="btn btn-primary"
                id="skipButton"
                style={{width:'150px;border: 2px solid white !important;'}}
              >
                Skip 
              </button>
              {/* Next Question Button */}
              <button
                onClick={getNextQuestion}
                className="btn btn-primary slick-next"
                id="nextButton"
                style={{width:'150px;border: 2px solid white !important;'}}
              >
                Next 
              </button>
              {/* Submit Button */}
              <button onClick={checkAnswer} 
              className="btn-primary btn submob"  
              id="submimob"
              style={{width:'150px;border: 2px solid white !important;'}}>
                Submit
              </button>
            </div>
          </div>

          {/* Right Column */}
          <div className="col-lg-4 mobfull" style={{ width: '29%' }}>
            {/* Remaining Timer */}
            <div className="dark-box ">
              <div className="row remaining-timer">
                <div className="col-lg-6 col-md-6 col-6">
                  <h4 className="text-white">
                    <b>Timer</b>
                  </h4>
                  <span id="countdown1">{formatTime(countdown)}</span>
                </div>
                <div className="col-lg-6 col-md-6 col-6 time-number">
                  <h4 className="text-white">
                    <b>Total Time</b>
                  </h4>
                  <span className="timer-numbers">
                    {formatTotalTime(totalTime)}
                  </span>
                </div>
              </div>
            </div>
            {/* Questions List */}
            <div className="dark-box mt-4 question-icon">
              <h3 className="text-white">
                <b>Questions</b>
              </h3>
              <i className="las la-angle-up arrow" onClick={scrollToTop}></i>
              <ul
                className="list-unstyled questions-scroll"
                id="slick"
                ref={scrollRef}
              >
                {questionsAndAnswers.map((qa, index) => (
                  <li key={index} className="li-img">
                    <div className="ques">
                      {qa.skipped ? (
                        <h4 className="que-skip">
                          <span>
                            {index + 1}. Question
                            <h4 id="skip-que">
                              <span>skipped</span>
                            </h4>
                          </span>
                        </h4>
                      ) : (
                        <h4 className="question-not-ans">
                          {index < activeQuestionIndex ? (
                            <span>
                              {index + 1}. Question
                              {!qa.answered && (
                                <h4 id="no-ans-que">
                                  <span> not answered</span>
                                </h4>
                              )}
                            </span>
                          ) : (
                            <span>
                              {index + 1}. {qa.question}
                            </span>
                          )}
                        </h4>
                      )}

                      {qa.similarityScore !== null && (
                        <>
                          {qa.similarityScore < 6 ? (
                            <div className="noqueimg" id="no-ans-que"
                              style={{
                                padding: 4,
                                borderRadius: 99,
                              }}
                            >
                              <div className="image-container">
                             <img src={wrong} alt="mark" style={{ width: '8%',marginTop:'-5px' }} />
                             </div>
                              {/* <Close color="error" style={{}} /> */}
                            </div>
                          ) : (
                             <div className="noqueimg" id="no-ans-que"
                              style={{
                                padding: 4,
                                borderRadius: 99,
                              }}
                            >
                                <img src={tick} alt="mark" style={{ width: '8%',marginTop:'-5px' }}/>
                              {/* <Check color="success" /> */}
                            </div>
                          )}
                        </>
                      )}

                      <audio hidden ref={audioRef} controls></audio>
                    </div>
                  </li>
                ))}
              </ul>
              <i
                className="las la-angle-down  arrow down"
                onClick={scrollToBottom}
              ></i>
            </div>
            <textarea
              name=""
              hidden
              ref={textAreaRef}
              value={answer}
              id=""
            ></textarea>
		
          </div>
        </div>
      </div>
    </>
  );
};

export default InterviewSereen;
