import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";

import "./CustomTimerScreen.css";
import CustmInterviewScreen from "../Custominterviewscreen/CustmInterviewScreen";

const CustomTimerScreen = () => {
  const [timeLeft, setTimeLeft] = useState(10);
  const [showTimer, setShowTimer] = useState(true);

  useEffect(() => {
    if (timeLeft >= 0) {
      const timerCircle = document.querySelector(".timer svg circle + circle");
      document.querySelector(".timer").classList.add("animatable");

      const countdownTimer = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime > 0) {
            const normalizedTime = prevTime / 10;
            timerCircle.style.strokeDashoffset = 1 - normalizedTime;
            return prevTime - 1;
          } else if (prevTime === 0) {
            timerCircle.style.strokeDashoffset = 0;
            clearInterval(countdownTimer);
            localStorage.setItem("timerCompleted", true); // Set flag in local storage
            setShowTimer(false);
            return 0;
          }
        });
      }, 1000);

      return () => clearInterval(countdownTimer);
    }
  }, [timeLeft]);

  if (!showTimer) {
    return <CustmInterviewScreen />;
  }

  return (
    <Modal
      show={showTimer}
      onHide={() => {}}
      backdrop="static"
      keyboard={false}
      centered
      className="custom-modal"
    >
      <Modal.Body className="text-center">
        <h1>
          <b>Hi John Doe</b>
        </h1>
        <h4>Your Interview starts in</h4>
        <div className="timer animatable">
          <svg>
            <circle cx="50%" cy="50%" r="90" />
            <circle cx="50%" cy="50%" r="90" pathLength="1" />
            <text x="100" y="100" textAnchor="middle">
              <tspan dy="10">{timeLeft}</tspan>
            </text>
            <text x="100" y="120" dy="15" textAnchor="middle">
              seconds
            </text>
          </svg>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CustomTimerScreen;
